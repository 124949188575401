import {useContext} from "react";
import {StoreContext} from "../stores/StoreLoader";
import {GridTypes} from "../pages/MainGridPage";
import {IView} from "../stores/InterfaceStore";
import {useGridType} from "./useGridType";

export const useFloatingBlocksThemeLayout = () => {
    const {interfaceStore, styleStore} = useContext(StoreContext);
    const gridType = useGridType();


    return styleStore.themeID === "2" && (gridType !== GridTypes.terraced || interfaceStore.view !== IView.DEFAULT)
}
