import {useContext} from "react";

import classNames from "classnames";
import { useTranslation } from 'react-i18next';

import FontAwesome from "../../utilities/FontAwesome";
import { LanguageDropDown } from './LanguageDropDown';
import styles from "../styles/LanguageTool.module.scss";
import {useComponentVisible} from "../../../hooks/useComponentVisible";
import {StoreContext} from "../../../stores/StoreLoader";
import {color_lightness} from "../../../utils/colorcontrast";
import {observer} from "mobx-react";

const LanguageTool = observer(() => {
    const {styleStore} = useContext(StoreContext);
    const { t } = useTranslation('translation');
    const {ref, setIsComponentVisible, isComponentVisible} = useComponentVisible(false);

    const toggleOpen = (): void => {
        setIsComponentVisible(!isComponentVisible);
    }

    let backgroundColor = "white", color = "black";
    if (styleStore.themeID === "1") {
        const lightness = color_lightness(styleStore.cssVars.primaryNavBackground);
        if (lightness > 50) {
            backgroundColor = "var(--secondaryNavBackground)";
            color = "var(--secondaryNavBackgroundTextColor)";
        }
    }

    const languageButtonContainerClassName = classNames({
        [styles.languageButtonContainer]: true,
        [styles.whiteText]: isComponentVisible,
    });
    const translationClassName = classNames({
        [styles.translationElement]: true,
        "hidden": !isComponentVisible,
    });

    const containerClassName = classNames({
        [styles.container]: true,
    });

    return (
        <div className={containerClassName} ref={ref}>
            <div className={styles.languageButtonShadow} />
            <button className={languageButtonContainerClassName} style={{backgroundColor, color}} onClick={toggleOpen} >
                <span>
                    <div id={"arrows-small-width"}>
                        <FontAwesome prefix={"fas"} name={"fa-angle-down"} rotate={isComponentVisible ? 180 : undefined}/>
                    </div>
                    {t('Language')}
                    <div id={"arrows-large-width"}>
                        <FontAwesome prefix={"fas"} name={"fa-angle-down"} rotate={isComponentVisible ? 180 : undefined}/>
                    </div>
                </span>
            </button>
            <div className={translationClassName}>
                <div className={styles.inner}>
                    <div className={styles.selectContainer}>
                        <FontAwesome prefix={"fas"} name={"fa-language"} className={styles.icon} size={"2x"}/>
                        <span className={styles.heading1}>{t('Select your language')}</span>
                    </div>
                    <div className={styles.learnMoreContainer}>
                        <span>{t('Set your preferred language. ') + ' '}</span>
                        {/*<ClickableLink>{t('Learn more.')}</ClickableLink>*/}
                    </div>
                    <div className={styles.dropdownContainer}>
                        <div className={styles.heading2}>
                            <span>{t('Language')}</span>
                        </div>
                        <LanguageDropDown handleSelect={toggleOpen}/>
                    </div>
                    <div className={"hidden"} id={"google_translate_element"} />
                </div>
            </div>
        </div>
    );
});

export default LanguageTool;
