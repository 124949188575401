import classnames from 'classnames';
import {forwardRef, MutableRefObject, useEffect, useRef} from "react";
import FontAwesome from "../utilities/FontAwesome";
import styles from './notifications.module.scss';

export interface INotification {
    type: 'info' | 'success' | 'warning' | 'error',
    title?: string,
    message: string,
    timeOut?: number,
    onClick?: any,
    onRequestHide?: any,
}

export const Notification= forwardRef((props: INotification, ref: MutableRefObject<HTMLDivElement>) => {
    const {type = 'info', title = "", message = "", timeOut = 1500, onClick = () => {}, onRequestHide = () => {}} = props;
    const timer = useRef<any>(null);

    useEffect(() => {
        if (timeOut !== 0) {
            timer.current = setTimeout(requestHide, timeOut);
        }

        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
        }
    }, []);

    function handleClick() {
        onClick();
        requestHide();
    }

    function requestHide() {
        onRequestHide();
    }

    let icon;
    switch (type) {
        case "info":
            icon = <FontAwesome prefix={'fas'} name={'fa-info-circle'} />
            break;
        case "success":
            icon = <FontAwesome prefix={'fas'} name={'fa-check-circle'} />
            break;
        case "warning":
            icon = <FontAwesome prefix={'fas'} name={'fa-exclamation-circle'} />
            break;
        case "error":
            icon = <FontAwesome prefix={'fas'} name={'fa-times-circle'} />
            break;
    }

    const className = classnames({
        [styles.notification]: true,
        [styles[`notification-${type}`]]: true,
    });
    return (
        <div className={className} onClick={handleClick} ref={ref}>
            <div role="alert">
                {icon}
                {title && <h4 className={styles.title}>{title}</h4>}
                <div>{message}</div>
            </div>
        </div>
    );
})
