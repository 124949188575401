import {FC, useContext, useEffect, createElement, useMemo, PropsWithChildren} from 'react';
import i18nHeadTags from "../../internationalization/i18nHeadTags";
import Head from "next/head";
import {StoreContext} from "../../stores/StoreLoader";
import {withoutLocaleInURI} from "../../internationalization/i18nURI";
import URI from 'urijs';
import {observer} from "mobx-react";
import {buildIconUrl} from "../../utils/SchoolBlocksUtilities";
import {unsupportedLocalesString} from "@soprisapps/components/internationalization/SupportedLocales"
import {useAppAllowed} from "../../hooks/useAppAllowed";
import {isSchoolFeedStandalone} from "../../utils/SchoolBlocksUtilities";

export const PageHead: FC<PropsWithChildren<{
    currentFullUrl: string,
    currentTitle: string,
}>> = observer((props) => {
    const allStores = useContext(StoreContext);
    const {organizationStore, styleStore} = allStores;

    const title = useMemo(() => {
        let t;
        switch (URI(withoutLocaleInURI(props.currentFullUrl || "")).path()) {
            case "/":
                t = "Home - ";
                break;
            case "/calendar":
                t = "Calendar - ";
                break;
            case "/search":
                t = "Search - ";
                break;
            case "/feed":
                t = "Feed - "
                break;
            case "/cms/user/feed":
                t = "Feed - ";
                break;
            case "/cms/user/following":
                t = "Following - ";
                break;
            case "/cms/user/myCalendar":
                t = "My Calendar - ";
                break;
            case "/cms/user/settings":
                t = "My Settings - ";
                break;
            case "/cms/admin/organizationSettings":
                t = "Settings - ";
                break;
            default:
                t = props.currentTitle + " - ";
                break;
        }
        t += organizationStore.organization.title;
        return t;
    }, [props.currentFullUrl, props.currentTitle]);

    const cssVars = useMemo(() => {
        if (styleStore.cssVars) {
            return createElement("style", null, `:root {${Object.entries(styleStore.cssVars).reduce((acc, curr) => {
                const [key, val] = curr;
                acc += `--${key}: ${val};`
                return acc;
            }, "")}}`);
        }
        return "";
    }, [styleStore.cssVars]);

    const icon = organizationStore.organization?.json_data?.settings?.favicon;
    const appAllowed = useAppAllowed();
    // check if web apps should be enabled for the current organization
    const shouldSendManifest = !!(appAllowed &&
        organizationStore.organization.json_data?.settings?.webapp?.enabled &&
        organizationStore.organization?.json_data?.settings?.webapp?.icon); // organization must have uploaded icon

    let customShortcutIcon: undefined | string, customAppleIcon: null | string = null;
    if (icon) {
        customShortcutIcon = buildIconUrl(icon, 16);
        customAppleIcon = buildIconUrl(icon, 192);
    }

    const googleTranslateElementInit = () => {
        const func = window as IWindow;
        new func.google.translate.TranslateElement({
            pageLanguage: 'en',
            includedLanguages: unsupportedLocalesString,
            autoDisplay: true,
        }, 'google_translate_element');
    }


    useEffect(() => {
        const googleTranslateScript = document.createElement('script');
        googleTranslateScript.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
        googleTranslateScript.type = "text/javascript";
        googleTranslateScript.defer = true;
        document.head.appendChild(googleTranslateScript);
        window.googleTranslateElementInit = googleTranslateElementInit;

        const google_analytics_id =organizationStore.organization.json_data?.settings?.analytics?.google_analytics_id
        if (google_analytics_id){
            const googleAnalyticsScript = document.createElement('script');
            googleAnalyticsScript.src = "https://www.googletagmanager.com/gtag/js?id="+google_analytics_id;
            googleAnalyticsScript.async = true;
            const html= "window.dataLayer = window.dataLayer || [];"+
                "function gtag(){window.dataLayer.push(arguments)};"+
                "gtag('js', new Date());"+
                "gtag('config', '"+google_analytics_id+"');"
            const google_script = document.createElement('script');
            google_script.innerHTML = html
            document.head.appendChild(googleAnalyticsScript);
            document.head.appendChild(google_script);
        }
    }, [])

    return <Head>
        <title>{title}</title>
        { i18nHeadTags(allStores) }
        <meta key="ua-compatible" httpEquiv={'x-ua-compatible'} content={'ie=edge,chrome=1'} />
        <meta key="charset" charSet={'utf8'} />
        <meta name={'viewport'} content={'width=device-width, initial-scale=1, shrink-to-fit=no'} />
        <meta key="og-type" property={'og:type'} content={'website'} />
        <meta key="og-site-name" property={'og:site_name'} content={title} />
        <meta key="og-title" property={'og:title'} content={title} />
        {isSchoolFeedStandalone() ?
            <meta name={'description'} content={`${props.currentTitle}’s Community Engagement Page.`}/> :
            <>{organizationStore.organization?.json_data?.settings?.headerText &&
                <meta name={'description'} content={organizationStore.organization.json_data.settings.headerText} />}
            </>}
        {organizationStore.organization?.json_data?.settings?.headerText && <meta key="og-description" property={'og:description'} content={organizationStore.organization.json_data.settings.headerText} />}
        {organizationStore.organization?.json_data?.settings?.appearance?.blocks && <meta key="theme-color" property={'theme-color'} content={organizationStore.organization.json_data.settings.appearance.blocks} />}
        {customShortcutIcon ? <link rel="shortcut icon" href={customShortcutIcon} type={"image/png"} /> : <link rel="shortcut icon" href={"/favicon.ico"} type="image/x-icon" />}
        {customAppleIcon && <link rel="apple-touch-icon" href={customAppleIcon}/>}
        <link rel={"stylesheet"} href={'//fonts.googleapis.com/css?family=Open+Sans:400italic,700italic,400,600,700&display=swap'} />
        <link rel={"stylesheet"} href={"https://unpkg.com/leaflet@1.3.4/dist/leaflet.css"}/>
        {shouldSendManifest && <link rel="manifest" href="/manifest.webmanifest" />}
        {cssVars}
        {props.children}
    </Head>
});
