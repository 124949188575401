import modalStyles from "../modals/styles/Modal.module.scss";
import FontAwesome from "./FontAwesome";
import {transformOrganizationType} from "../../utils/StringUtilities";
import styles from "./styles/UnpublishedPageInfobox.module.scss";
import BigRoundedButton from "../admin/utilities/BigRoundedButton";
import {useContext} from "react";
import {StoreContext} from "../../stores/StoreLoader";
import NotificationManager from "../notifications/NotificationManager";
import WatsonApi from "../../backends/WatsonApi";
import {runInAction} from "mobx";

export async function togglePublished(organizationStore: any, modalStore: any) {
    try {
        const client = await WatsonApi();
        await client.apis.organizations.organizations_toggle_published({
            id: organizationStore.currentOrganization.id,
            data: {
                published: organizationStore.currentOrganization.published,
            },
        });

        const expandedFields: string[] = [];
        organizationStore.currentOrganization.json_data?.terracedGrid?.enabled
            ? expandedFields.push("sub_navigations")
            : expandedFields.push("microsite");
        if (organizationStore.navigation.filter((nav: Navigation) => nav.organization_id === organizationStore.currentOrganization.id).length > 0) {
            expandedFields.push("title_organization.navigation");
        }

        const result = await client.apis.organizations.organizations_read({
            id: organizationStore.currentOrganization.id,
            expand: expandedFields,
        });

        runInAction(() => {
            const org = JSON.parse(result.data);
            organizationStore.currentOrganization.published = org.published;
            organizationStore.currentOrganization.sub_navigations = org?.sub_navigations;
            organizationStore.currentOrganization.microsite = org?.microsite;
            if (org?.title_organization?.navigation){
                organizationStore.navigation = org?.title_organization?.navigation;
            }
        });

        modalStore.removeTop();
    } catch (e) {
        if (e.response?.data){
            const parsedData = JSON.parse(e.response?.data);
            console.error(e);
            NotificationManager.error(parsedData[0], "Could not change the status of the page",  5000);
        }
        else {
            NotificationManager.error(e.message);
        }


        modalStore.removeTop();
    }
}


export function publishedToggleOnClick(organizationStore: any, modalStore: any) {
    const orgType = transformOrganizationType(organizationStore.currentOrganization.type)
    const error = `Sorry, but we couldn't ${!organizationStore.currentOrganization.published ? "publish" : "unpublish"} the ${orgType}. Please try again later or contact an administrator for
                assistance.`
    modalStore.addModal({
        type: "blockui",
        idleContent: (props) => (
            <div id="sb-fluxRestoreDialog" style={{ cursor: "default" }}>
                <h3 style={{ marginBottom: "1em", whiteSpace: "pre-line" }}>
                    {organizationStore.currentOrganization.published
                        ? `Are you sure you want to unpublish this page?\n
                        All descendant pages will become unpublished and links to these pages will be hidden.`
                        : `Are you sure you want to publish this page?\n
                        All descendant pages will be published and links to these pages will be restored.`}
                </h3>
                <button className="btn btn-default" onClick={modalStore.removeTop}>Cancel</button>
                <button className="btn btn-default btn-info" onClick={props.onConfirm}>
                    {organizationStore.currentOrganization.published
                        ? "Unpublish Page"
                        : "Publish Page"}
                </button>
            </div>
        ),
        pendingContent: () => (
            <div id="sb-fluxRestoreDialogMessageRestoring">
                <h3>
                    <FontAwesome name="fa-spinner" prefix="fas" spin style={{ marginRight: "0.5em" }} />
                    {organizationStore.currentOrganization.published ? "Unpublishing page..." : "Publishing page..."}
                </h3>
            </div>
        ),
        failureContent: () => (
            <div id="sb-fluxRestoreDialogMessageRestoreFailed">
                <h3>{error}</h3>
            </div>
        ),
        onConfirm: () => togglePublished(organizationStore, modalStore),
        closeEffect: () => {
            document.body.classList.remove(modalStyles.modalOpen);
        },
    });
}

export function UnpublishedInfoBox({ unpublishedItemType }: { unpublishedItemType: string }) {
    const { organizationStore, modalStore } = useContext(StoreContext);

    return (
        <div className={styles.container}>
            <div>
                <div>
                    {`This ${unpublishedItemType} is unpublished! Only editors and admins can view this content.`}
                </div>
                <BigRoundedButton onClick={() => publishedToggleOnClick(organizationStore, modalStore)}>
                    Publish
                </BigRoundedButton>
            </div>
        </div>
    );
}
