import React, {PropsWithChildren} from 'react';
import ClickableLink from "../utilities/ClickableLink";
import URI from 'urijs';

const MailToLink = (props: PropsWithChildren<{
    recipient: string,
    subject?: string,
    body?: string,
    cc?: string,
    bcc?: string,
    className?: string,
}>) => {
    const {recipient, subject, body, cc, bcc, ...validDomAttributes} = props;

    const mailtoUri = new URI(`mailto:${recipient}`);

    const queryParams: Record<string, string | undefined> = {
        subject,
        body,
        cc,
        bcc,
    };

    Object.keys(queryParams).forEach(key => {
        if (queryParams[key]) {
            mailtoUri.addQuery(key, queryParams[key] as string);
        }
    });

    const formattedMailtoLink = mailtoUri.toString().replace(/\+/g, ' ');

    return (
        <ClickableLink
            href={formattedMailtoLink}
            {...validDomAttributes}
        >
            {props.children}
        </ClickableLink>
    );
};

export default MailToLink;
