import {useContext, useState} from 'react';
import {modalStore, StoreContext} from "../stores/StoreLoader";
import {observer} from "mobx-react";
import {gridTypesEnum, gridRowHeights} from "../utils/SchoolBlocksUtilities";
import styles from "./styles/MainGridPage.module.scss";
import {Microsite, useMicrosite} from "../components/header/microsite/Microsite";
import classNames from 'classnames';
import {getBackgroundImageValue} from "../stores/StyleStore";
import ClickableLink from "../components/utilities/ClickableLink";
import FontAwesome from "../components/utilities/FontAwesome";
import {blockObjectFactory} from "../components/blocks/SchoolBlocks/blockUtils";
import {useGridType} from "../hooks/useGridType";
import dynamic from "next/dynamic";
import FollowPageButton from "../components/misc/FollowPageButton";
import {MicrositeToggleProps} from "../components/header/microsite/MicrositeToggle";
import TerraceSubPageHeader from "../components/misc/TerraceSubPageHeader";
import {UnpublishedInfoBox} from "../components/utilities/UnpublishedInfoBox";
import {useToggleFollowPage} from "../hooks/useToggleFollowPage";
import ImageComponent from "../components/utilities/ImageComponent";

// @ts-ignore
const PackeryGrid = dynamic(() => import("../components/grids/PackeryGrid"));
const Breadcrumbs = dynamic(() => import("../components/misc/Breadcrumbs"));
const TerracedGrid = dynamic(() => import("../components/grids/TerracedGrid"));

const LoginBox = dynamic(() => import("../components/misc/LoginBox"));
const MicrositeToggle = dynamic<MicrositeToggleProps>(() => import("../components/header/microsite/MicrositeToggle").then(module =>  module.MicrositeToggle));
const LoginButtons = dynamic(() => import("../components/admin/sidebar/items/LoginButtons"));

export interface IBreadcrumb {
    follower: boolean,
    isPrivate: boolean,
    type: "department" | "group" | "team" | "section" | "classroom" | "page" | "sharedfiles",
    label: string,
    url: string,
    model?: {
        id?: string,
    },
}

export const GridTypes = {
    terraced: "terraced",
    packery: "packery",
}

const MainGridPage: React.FC<{}> = observer(props => {
    const {userStore, organizationStore} = useContext(StoreContext);

    const orgType = organizationStore.currentOrganization.type
    const isClassroom = orgType === "classroom";
    const [showGrid, setShowGrid] = useState(isClassroom ? !!userStore.id : true);

    const gridPage = orgType === "group" || orgType === "section" || orgType === "department" || orgType === "team";
    const mainPage = orgType === "school" || orgType === "district" || orgType === "organization";

    const {shouldShowMicrosite, canShowMicrosite, microsite} = useMicrosite();
    const [micrositeOpen, setMicrositeOpen] = useState(false);

    const toggleFollowing = useToggleFollowPage();

    const gridType = useGridType();

    const gridClassName = classNames({
        "sb-content-width": true,
        "sb-grid-with-microsite": shouldShowMicrosite && gridType !== GridTypes.terraced,
        [styles.grid]: true,
        [styles.gridWithMicrosite]: shouldShowMicrosite && gridType !== GridTypes.terraced,
        [styles.gridGridPage]: gridPage || isClassroom,
        [styles.fullWidthPage]: gridType === GridTypes.terraced,
        [styles.fullWidthPageSubPage]: gridType === GridTypes.terraced && organizationStore.organization.id !== organizationStore.currentOrganization.id,
    })
    const micrositeClassName = classNames({
        [styles.microsite]: true,
        [styles.micrositeOpen]: micrositeOpen,
        [styles.micrositeTerrace]: gridType === GridTypes.terraced,
    })
    const micrositeToggleClassName = classNames({
        [styles.micrositeToggleButton]: true,
        [styles.micrositeToggleButtonTerrace]: gridType === GridTypes.terraced,
    })
    const loginWrapperClassName = classNames({
        "'sb-main-content": true,
        [styles.loginWrapper]: true,
    })
    const gridContainerClassName = classNames({
        "sb-main-content": true,
        "packery": gridType === GridTypes.packery,
    });
    const containerClassName = classNames({
        [styles.container]: true,
    })

    const classroomType = organizationStore.currentOrganization.json_data?.settings?.linkedClassroomType;
    const isLinkedClassroom = !!organizationStore.currentOrganization.json_data?.settings?.linkedClassroomId;

    const header = <div className={styles.header}>
        {gridType === GridTypes.terraced
            ? <TerraceSubPageHeader breadcrumbs={organizationStore.currentOrganization.breadcrumbs} editable={true}/> :
        <div className={styles.classroomHeaderTopBlock}>
            <div>
                {shouldShowMicrosite && <button aria-label={`Toggle ${microsite.title} Navigation`} className={micrositeToggleClassName} onClick={() => setMicrositeOpen(v => !v)}>
                    <FontAwesome name={"fa-bars"} size={"2x"} prefix={'fas'}/>
                </button>}
                <Breadcrumbs breadcrumbs={organizationStore.currentOrganization.breadcrumbs}
                             hasMicrosite={shouldShowMicrosite}
                             editable={true} />
            </div>
            <div className={styles.rightAlignedHeader}>
                {isClassroom && <div>
                    <div className={styles.classroomTeacher}>
                        {organizationStore.currentOrganization.teachers?.map(teacher => {
                            const modalObj = {
                                id: teacher.id,
                                type: "schoolBlocksPerson",
                                blockObj: blockObjectFactory("person", {id: teacher.id, blockModel: teacher} as IPersonBlock),
                            }

                            return <div key={teacher.id} className={styles.classroomMultipleTeachers}>
                                <button onClick={() => modalStore.addModal(modalObj)}>
                                    <div className={`${styles.classroomTeacherImage} sb-circular-profile-image`}
                                         role="presentation" aria-hidden="true"
                                         title={`${teacher.fname} ${teacher.lname}`}
                                         style={getBackgroundImageValue(teacher.avatarUrl || require("../assets/images/person-placeholder.jpg"))}>
                                    </div>
                                    <div>
                                        {`${teacher.fname} ${teacher.lname}`}
                                    </div>
                                </button>
                            </div>
                        })}
                    </div>
                </div>}
                {userStore.editor && canShowMicrosite && (gridType === GridTypes.packery) &&
                    <div className={styles.classroomHeaderLabels}>
                        <MicrositeToggle displayText={true}/>
                    </div>}
                {isClassroom && <div className={`${styles.classroomHeaderLabels} sb-main-content`}>
                    {isLinkedClassroom &&
                        <ClickableLink href={organizationStore.currentOrganization.json_data.settings.linkedClassroomUrl}
                                       className={`btn btn-default ${styles.classroomLinkedFloatingButton}`}
                                       role="button" title={"Link to Google Classroom"}>
                            {classroomType === "google" && <>
                                <ImageComponent alt={"Google Classroom Icon"} src={require("../assets/images/google_classroom_green.png")}/>
                                <div className={styles.hiddenWhenSmall}>Google Class</div></>}
                        </ClickableLink>}
                </div>}
                <div className={`${styles.classroomHeaderLabels} sb-main-content`}>
                    <FollowPageButton follower={userStore.follower} toggleFollowing={toggleFollowing} organizationTitle={organizationStore.currentOrganization.title}/>
                </div>
            </div>
        </div>}
    </div>

    return <div className={containerClassName}>
        {!organizationStore.currentOrganization.published && <UnpublishedInfoBox unpublishedItemType={"page"} />}
        <div id={`sb-main-content`} className={gridClassName}>
            {(gridPage || isClassroom) && header}
            {shouldShowMicrosite && <div className={micrositeClassName}>
                <Microsite setMicrositeOpen={setMicrositeOpen} micrositeOpen={micrositeOpen}/>
            </div>}
            {mainPage || (gridPage && userStore.viewer) || (isClassroom && showGrid) ?
                <div className={`sb-grid-wrapper ${styles.packery}`}>
                    <div id="main-grid-container" className={gridContainerClassName}
                         data-grid-perms={userStore.editor ? 1 : 0}>
                        {gridType === GridTypes.terraced ? <TerracedGrid key={organizationStore.currentOrganization.id} /> :
                            <PackeryGrid
                            hasMicrosite={shouldShowMicrosite}
                            gridType={gridTypesEnum.FLEX_GRID}
                            htmlId={'main-grid'}
                            key={organizationStore.currentOrganization.id}
                            rowHeight={gridRowHeights.default}
                            {...props}
                        />}
                    </div>
                </div> : <>
                    {isClassroom ? <div className={loginWrapperClassName}>
                        <LoginBox
                            customHeader={'Login to see the classroom page.'}
                            handleSkipLogin={() => setShowGrid(true)}
                        /></div> : <div className="sb-content-width">
                            <div className="sb-error-block">
                                <div className="sb-error-message">
                                    <h3 className="text-danger">
                                        <b>This is a private section that you do not have permission to view.</b>
                                    </h3>
                                    {!!userStore.id || <div style={{marginTop: "1rem"}}>
                                        <LoginButtons/>
                                    </div>}
                                </div>
                            </div>
                        </div>}
                </>}
        </div>
    </div>
});

export default MainGridPage;
