import React, {useContext, useState, useEffect, useRef} from "react";
import classNames from "classnames";
import {CSSTransition} from 'react-transition-group';
import {observer} from "mobx-react";
import Menu, { SubMenu, MenuItem } from 'rc-menu';
import Button from "../../../utilities/Button";
import {ErrorBoundary} from "../../../utilities/ErrorBoundary";
import {StoreContext} from "../../../../stores/StoreLoader";
import NavigationLink from "../NavigationLink";
import HelpScout from "../../../support/HelpScout";
import styles from "./MobileNav.module.scss";
import {LogoutButton} from "../../../admin/sidebar/items/LogoutButton";
import FollowingButton from "../../../admin/sidebar/items/FollowingButton";
import ClickableLink from "../../../utilities/ClickableLink";
import {Shortcut} from "../../shortcuts/Shortcut";
import {IView} from "../../../../stores/InterfaceStore";
import FontAwesome from "../../../utilities/FontAwesome";

export function DefaultLink(props: {
    item: IMenuItem,
}) {
    let content = <>
        {props.item.icon && <FontAwesome className={styles.metisMenuIcon} fixedWidth name={props.item.icon} />}
        {props.item.label}
    </>

    if (props.item.to === "/logout") {
        return <LogoutButton className={styles.link}>
            {content}
        </LogoutButton>
    }else if (props.item.to === "/following") {
        return <FollowingButton className={styles.link}>
            {content}
        </FollowingButton>
    } else if (props.item.onClick) {
        return <Button onClick={props.item.onClick} className={styles.link}>
            {content}
        </Button>
    } else if (props.item.content) {
        return <span style={{cursor: "pointer"}} className={styles.link}>
            {content}
        </span>
    }

    return <NavigationLink
        className={styles.link}
        navigationObj={props.item.navigationObj} // static links like Login won't have a navigationObj defined
        href={props.item.to}
    >
        {content}
    </NavigationLink>
}

const transitionClassNames = {
    enter: styles.transitionEnter,
    enterActive: styles.transitionEnterActive,
    enterDone: styles.transitionEnterDone,
    exit: styles.transitionExit,
    exitActive: styles.transitionExitActive,
    exitDone: styles.transitionExitDone,
};

export const navigationContent = (organizationStore) => {

    let navTree: Array<IMenuItem> = [];
    if (organizationStore.navigation.length > 0) {
        navTree = organizationStore.navigationTree
            .filter(item => item.enabled)
            .map((item, rootIdx) => {
                const children = item.children
                    .filter(item => item.enabled)
                    .map((item, childIdx) => {
                        const grandchildren = item.children
                            .filter(child => child.enabled)
                            .map((child, grandchildIdx) => {
                                return {
                                    icon: "empty-icon",
                                    label: child.title,
                                    id: `${rootIdx}-${childIdx}-${grandchildIdx}`,
                                    navigationObj: child,
                                };
                            });
                        const settings: IMenuItem = {
                            icon: item.icon,
                            label: item.title,
                            id: `${rootIdx}-${childIdx}`,
                            navigationObj: item,
                        };
                        if (grandchildren && grandchildren.length > 0) {
                            settings.content = grandchildren;
                        }
                        return settings;
                    });
                const settings: IMenuItem = {
                    icon: item.icon || "",
                    label: item.title,
                    id: rootIdx,
                    navigationObj: item,

                };
                if (children && children.length > 0) {
                    settings.content = children;
                }
                return settings;
            });
    }

    return navTree;
}

export function RecursiveRcMenu(props: {
    content: IMenuItem[],
    isSubMenu: boolean,
    [k: string]: any,
}) {
    const {content, isSubMenu, ...restProps} = props;
    const [openKeys, setOpenKeys] = useState<string[]>([])
    const Component = isSubMenu ? SubMenu : Menu;
    return <Component
        onOpenChange={isSubMenu ? undefined : (keys: string[]) => {
            let nextKeys = keys;
            if (keys.length > 0) {
                const lastOpenedKey = keys[keys.length - 1];
                nextKeys = nextKeys.filter(k => lastOpenedKey.includes(k));
            }
            setOpenKeys(nextKeys);
        }}
        openKeys={isSubMenu ? undefined : openKeys}
        {...restProps}
    >
        {content.map((item, idx) => {
            if (item.content && item.content.length > 0) {
                return <RecursiveRcMenu
                    content={item.content}
                    isSubMenu={true}
                    title={<DefaultLink item={item} />}
                    key={item.id}
                    className={styles.menuItem}
                />
            } else {
                return <MenuItem key={item.id} className={styles.menuItem}>
                    <DefaultLink item={item} />
                </MenuItem>
            }
        })}
    </Component>
}

const MobileNav = observer((props: MobileNavProps) => {
    const {organizationStore, interfaceStore} = useContext(StoreContext);
    const {isOpen, toggleOpen, identifier} = props;
    const [incomingFocusElement, setIncomingFocusElement] = useState<Element | null>(null);
    const nodeRef = useRef(null);
    const mobileMenuContainerClassName = classNames({
        [styles.mobileMenuContainer]: true,
    });

    const shortcutListClassName = classNames({
        [styles.shortcutList]: true,
        [styles.shortcutListFourItems]: organizationStore.currentOrganization.shortcuts?.length === 4,
    });

    const handleKeydown = (evt) => {
        if (evt.code === "Escape") {
            handleClose();
        }
    }
    const handleClose = () => {
        if (isOpen) toggleOpen();
    }
    const focusCloseButton = () => {
        const closeButton = document.getElementById(identifier) as HTMLButtonElement;
        if (closeButton) closeButton.focus();
    }

    useEffect(() => {
        if (isOpen) {
            setIncomingFocusElement(document.activeElement);
            focusCloseButton();
        } else if (!isOpen && incomingFocusElement) (incomingFocusElement as HTMLLinkElement).focus();

        window.addEventListener('keydown', handleKeydown);
        return () => {
            window.removeEventListener('keydown', handleKeydown);
        }
    }, [isOpen]);

    useEffect(() => {
        const dropdownTop: HTMLElement | null = document.getElementById('exitContainer')
        if (dropdownTop) {
            dropdownTop?.focus();
            dropdownTop?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    },[isOpen])

    return (
        <ErrorBoundary>
            <span>
                <div className={mobileMenuContainerClassName}>
                    <CSSTransition in={isOpen} classNames={transitionClassNames} timeout={0} nodeRef={nodeRef}>
                        <nav className={styles.navContainer} role={'navigation'} ref={nodeRef}>
                            {isOpen && <div className={styles.exitContainer} id={'exitContainer'}>
                                {props.identifier === 'nav-id' && <ClickableLink className={styles.search} href={'/search'}>
                                    <span className={"fas fa-search"}/>
                                    <span>Search</span>
                                </ClickableLink>}
                                <Button className={styles.mobileClose}
                                        aria-label={'Close Navigation'}
                                        onClick={handleClose}
                                        id={identifier}
                                >
                                    <span>Close</span>
                                    <span className={"fas fa-times"}/>
                                </Button>
                            </div>}
                            {isOpen && <RecursiveRcMenu
                                content={props.content}
                                isSubMenu={false}
                                mode={"inline"}
                                className={styles.menuContainer}
                                selectable={false}
                                motion={{
                                    motionName: "rc-menu-collapse",
                                    motionEnter: true,
                                    motionLeave: true,
                                    onEnterStart: () => ({height: 0}),
                                    onEnterActive: (el) => ({"height": `calc(${el.children.length * 2}rem + ${el.children.length * 16}px)`}),
                                    onLeaveStart: (el) => ({"height": `calc(${el.children.length * 2}rem + ${el.children.length * 16}px)`}),
                                    onLeaveActive: () => ({height: 0}),
                                }}
                            />}
                            {interfaceStore.view === IView.DEFAULT &&
                                organizationStore.currentOrganization.shortcuts?.length > 0 &&
                                props.identifier === 'nav-id' &&
                                    <ul className={shortcutListClassName}>
                                        {organizationStore.currentOrganization.shortcuts.map((shortcut) => {
                                            return <Shortcut key={shortcut.id}
                                                             shortcut={shortcut}
                                                             canEdit={false}
                                                          />
                                        })}
                                    </ul>}
                            <span tabIndex={0}
                                  onFocus={focusCloseButton}
                            />
                        </nav>
                    </CSSTransition>
                    <HelpScout/>
                </div>
            </span>
        </ErrorBoundary>
    );
})

interface MobileNavProps {
    isOpen: boolean,
    toggleOpen: () => void,
    identifier: string,
    content: Array<IMenuItem>,
}

export default MobileNav;
