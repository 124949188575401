import {useContext, useRef} from 'react';
import * as React from 'react';
import {StoreContext} from "../../stores/StoreLoader";
import FontAwesome from "../utilities/FontAwesome";
import ClickableLink from "../utilities/ClickableLink";
import {IBreadcrumb} from "../../pages/MainGridPage";
import {sb_delay} from "../../utils/functions";
import NotificationManager from "../notifications/NotificationManager";
import styles from "../../pages/styles/MainGridPage.module.scss";
import WatsonApi from "../../backends/WatsonApi";
import modalStyles from "../modals/styles/Modal.module.scss";
import {runInAction} from "mobx";
import {observer} from "mobx-react";

let currentRequest;

function BreadcrumbIcon(props: {
    type: IBreadcrumb["type"],
}) {
    switch (props.type) {
        case "department":
        case "group":
            return <FontAwesome name={'fa-users'} prefix={'fas'} role="presentation" aria-hidden="true"/>;
        case "team":
            return <FontAwesome name={'fa-football-ball'} prefix={'fas'} role="presentation" aria-hidden="true"/>;
        case "section":
            return <FontAwesome name={'fa-sitemap'} prefix={'fas'} role="presentation" aria-hidden="true"/>;
        case "classroom":
            return <FontAwesome name={'fa-graduation-cap'} prefix={'fas'} role="presentation" aria-hidden="true"/>;
        case "page":
            return <FontAwesome name={'fa-file-alt'} prefix={'far'} role="presentation" aria-hidden="true"/>;
        default:
            return <FontAwesome name={'fa-sitemap'} prefix={'fas'} role="presentation" aria-hidden="true"/>;
    }
}

export function EditableTitle(props: {
    breadcrumb: IBreadcrumb,
    hasMicrosite?: boolean,
    editable?: boolean,
}) {
    const {label, type, model, url} = props.breadcrumb;
    const {i18nStore, organizationStore, userStore} = useContext(StoreContext);

    const editableTitleRef = useRef<HTMLSpanElement | null>(null);

    async function saveTitle(e) {
        const client = await WatsonApi();
        return await client.apis.organizations.organizations_partial_update({
            id: organizationStore.currentOrganization.id,
            data: {
                title: editableTitleRef.current?.innerText,
            }
        }).then((response) => {
            NotificationManager.success("Title update success.");
        }).catch((err) => {
            console.error(err);
            NotificationManager.error("Error updating title.")
        })
    }

    function handleTitleKeydown(e) {
        if (13 == e.keyCode) {
            e.preventDefault();
            editableTitleRef.current?.blur();
            saveTitle(e);
            return false;
        } else {
            return true;
        }
    }

    function handleTitleInput(e) {
        sb_delay(function () {
            saveTitle(e);
        }, 700);

        return false;
    }

    const isCurrentOrganization = organizationStore.currentOrganization.id === model?.id;

    if (organizationStore.currentOrganization.deleted && isCurrentOrganization) {
        return <span
            onKeyDown={handleTitleKeydown}
            onInput={handleTitleInput}
            className={styles.classroomTitle}
            data-id={organizationStore.currentOrganization.id}
            style={{textDecoration: "line-through"}}
        >
            {props.hasMicrosite || <BreadcrumbIcon type={type}/>}
            <span dangerouslySetInnerHTML={{__html: label}} />
        </span>
    } else if (props.hasMicrosite && !isCurrentOrganization) {
        return <ClickableLink className={styles.classroomTitle} href={url}>
            <span dangerouslySetInnerHTML={{__html: label}}/>
        </ClickableLink>
    } else {
        return <span
            onKeyDown={handleTitleKeydown}
            onInput={handleTitleInput}
            className={styles.classroomTitle}
            data-id={organizationStore.currentOrganization.id}
            ref={editableTitleRef}
            contentEditable={userStore.editor && props.editable}
            suppressContentEditableWarning
        >
            <span dangerouslySetInnerHTML={{__html: label}} />
        </span>
    }
}

const Breadcrumbs: React.FC<{
    breadcrumbs: IBreadcrumb[],
    hasMicrosite?: boolean,
    editable?: boolean,
}> = observer(props => {
    const {organizationStore, userStore, modalStore, gridStore} = useContext(StoreContext);

    let {breadcrumbs} = props;
    if (props.hasMicrosite) {
        breadcrumbs = [organizationStore.currentOrganization.microsite.navigation[0]];
    }

    const lastBreadcrumbIndex = breadcrumbs.length - 1;
    async function handleConfirm() {
        try {
            const client = await WatsonApi()
            await client.apis.organizations.organizations_soft_restore({
                id: organizationStore.currentOrganization.id,
            })

            const result = await client.apis.organizations.organizations_read({
                id: organizationStore.currentOrganization.id,
                expand: ["blocks", "micosite"],
            });
            runInAction(() => {
                const org = JSON.parse(result.data);
                organizationStore.currentOrganization.deleted = org.deleted
                gridStore.setBlocks(org.blocks, true);
                organizationStore.currentOrganization.microsite = org?.microsite
            })
            modalStore.removeTop();
        }
        catch (e)
        {
            console.log(e)
        }
    }

    return <div className={styles.breadcrumbs}>
        {breadcrumbs.map((breadcrumb, index) => {
            const itemKey = `${organizationStore.currentOrganization.id}-${breadcrumb?.model?.id || breadcrumb.url}`;

            if (index < lastBreadcrumbIndex) {
                return <span key={itemKey} className={styles.classroomTitle}>
                    <ClickableLink href={breadcrumb.url} className={`sb-organization-color-element-font`}>
                        <BreadcrumbIcon type={breadcrumb.type}/>&nbsp;
                        <span dangerouslySetInnerHTML={{__html: breadcrumb.label}}/>
                        {breadcrumb.isPrivate &&
                            <FontAwesome name={'fa-lock'} prefix={'fas'} title={`This is a private ${breadcrumb.type}`}
                                         style={{marginRight: "0.5em"}}
                                         aria-label={`This is a private ${breadcrumb.type}`}/>}

                    </ClickableLink>
                    {!(breadcrumbs[lastBreadcrumbIndex].type === 'page' && index + 1 === lastBreadcrumbIndex) &&
                    <FontAwesome name={'fa-chevron-right'} prefix={'fas'}/>}
                </span>
            } else if (breadcrumb.type !== 'page') {
                if (!props.hasMicrosite && organizationStore.currentOrganization.deleted) {
                    return <span key={itemKey} className={styles.classroomTitle}>
                        <EditableTitle breadcrumb={breadcrumb} editable={props.editable}/>
                        {userStore.editor && <>
                            <button id="btn-restore-organization"
                                    onClick={() => modalStore.addModal({
                                            type: "blockui",
                                            idleContent: (props) => <div id="sb-fluxRestoreDialog" style={{cursor: "default"}}>
                                                <h3 style={{marginBottom: "1em"}}>Are you sure you want to restore this {organizationStore.currentOrganization.type}?</h3>
                                                <button className="btn btn-default" onClick={modalStore.removeTop}>Cancel</button>
                                                <button className="btn btn-default btn-info" onClick={props.onConfirm}>Restore {organizationStore.currentOrganization.type}
                                                </button>
                                            </div>,
                                            pendingContent: () => <div id="sb-fluxRestoreDialogMessageRestoring">
                                                <h3><FontAwesome name="fa-spinner" prefix={'fas'} spin
                                                                 style={{marginRight: "0.5em"}}/>Restoring {organizationStore.currentOrganization.type}...
                                                </h3>
                                            </div>,
                                            failureContent: () => <div id="sb-fluxRestoreDialogMessageRestoreFailed">
                                                <h3>Could not restore {organizationStore.currentOrganization.type}. Please try again or contact an Administrator for help.</h3>
                                            </div>,
                                            onConfirm: handleConfirm,
                                            closeEffect: () => {
                                                document.body.classList.remove(modalStyles.modalOpen);
                                            },
                                    })}
                                    className={`btn btn-default sb-organization-color-element-bg ${styles.restoreButton}`}>Restore
                            </button>
                        </>}
                    </span>
                } else {
                    return <EditableTitle key={itemKey} breadcrumb={breadcrumb} hasMicrosite={props.hasMicrosite} editable={props.editable}/>
                }
            }
        })}
    </div>
})

export default Breadcrumbs;
